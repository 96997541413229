// require('slick-carousel');
require('../components/ui-components/modal-bootstrap-v3.4.1');

//const TooltipPositioning = require('../components/ui-components/tooltip.js').TooltipPositioning;
//const simpleAccordion = require('../components/ui-components/simpleAccordion').simpleAccordion;
const lazyImages = require('../components/ui-components/LazyLoad.js').lazyImages;
const videoPopup = require('../components/ui-components/VideoPopup').videoPopup;

const {onModeEnter, onModeLeave, getMode} = require('../media.js');
const StickyHeader = require('../StickyHeader.js').StickyHeader;
// const StickyTabs = require('../StickyHeader.js').StickyTabs;
//const FooterAccordion = require('../FooterAccordion.js').FooterAccordion;
// const Icons = require('../Icons.js').Icons;s
// const TemplatesSlider = require('../TemplatesSlider.js').TemplatesSlider;
// const ReviewsSlider = require('../ReviewsSlider.js').ReviewsSlider;
const effects = require('../effects.js');

const debug = require('../debug.js');
// const nailTab = require('../nail-salon-lending/tab.js');
// const ReviewsSlider_v2 = require('../ReviewsSlider.js').ReviewsSlider_v2;
const support = require('./support.js');
const $ = require("jquery");
//const schedulingSoftwarePage = require('../scheduling-software/main.js'); // because displayed error on other pages !!!!!!

// we do not need it for a while
//global.Registration = require('../registration/registration').Registration;

global.Login = require('../login/login').Login;
global.Feedback = require('../feedback/feedback').Feedback;
global._t = _t;

new StickyHeader();
// new StickyTabs();
//new FooterAccordion();
// new Icons();
// new TemplatesSlider();
// new ReviewsSlider();
// new ReviewsSlider_v2();
//new simpleAccordion();

// Handle lazy loading images
lazyImages();
// Handle lazy loading youtube video in popups
videoPopup();

support.supportChat();
//new TooltipPositioning();

effects.addRippleToButton($('.btn, li.to-previous-step'));

debug.showScreenSize();

var menuWasOpen = false;

// $('.header--togg-menu').click(function () {
//     menuWasOpen = !menuWasOpen;
//     closeMenu();
// });

// $('.morph-shape, .header--mob-togg-menu').click(function () {
//     menuWasOpen = false;
//     closeMenu();
// });

function closeMenu() {
    if (menuWasOpen) {
        $('body').addClass('menu--opened');
        //$('#header').addClass('header--stickly');
        $('.header--togg-menu, .header .morph-shape').addClass('open');

        // AnimateSvgOpenMobile(function () {
            $('.header--main-nav').addClass('open');
        // });

    } else {
        $('body').removeClass('menu--opened');
        //$('#header').removeClass('header--stickly');
        $('.header--main-nav').removeClass('open');
        $('.header--main-nav .menu--link-has-dropdown .submenu').removeClass('opened');
        // AnimateSvgCloseMobile(function () {
            $('.header--togg-menu, .morph-shape').removeClass('open');
        // });
    }
}

// $(window).resize(function () {
//     if ($(window).width() > 768) {
//         menuWasOpen = false;
//         closeMenu();
//     }
// });


// function openSubMenuOnTuch() {
//     $('.header--navigation-menu-container').on('click', '.header--main-nav-mobile .menu--link-has-dropdown', function () {
//         var get_data = $(this).data("submenu");
//         var sub_menu = $(this).closest('.header--main-nav-mobile .main-nav--menu').find('#' + get_data);
//         $(sub_menu).addClass('opened');
//     });
// }
// openSubMenuOnTuch();

// function goToPrevMenuOnTuch() {
//     $('.header--navigation-menu-container ').on('click', '.header--main-nav-mobile .submenu .to-previous-step', function (e) {
//         $('.submenu').removeClass('opened');
//         e.stopPropagation();
//     })
// }
// goToPrevMenuOnTuch();

function smoothScrollAnchorLinks() {
    $('.anchor-link').click(function (e) {
        e.preventDefault();
        $('.anchor-link').removeClass('active');
        //$(this).addClass('active');
        var target = $($(this).attr('href'));
        var offset_value = $(this).data('offset');

        if (getMode() != 'desktop') {
            offset_value = $(this).data('offset') - $(this).height();
        }
        if (getMode() == 'mob') {
            offset_value = 0;
        }

        $(window).resize(function () {
            if (getMode() != 'desktop') {
                offset_value = $(this).data('offset') - $(this).height();
            }
            if (getMode() == 'mob') {
                offset_value = 0;
            }
        });

        if (offset_value > 0) {
            console.log('has offset');
            if (target.length) {
                var scrollTo = target.offset().top - offset_value;
                $('body, html').stop().animate({scrollTop: scrollTo + 'px'}, 800);
            }
        } else {
            console.log('no offset');
            if (target.length) {
                var scrollTo = target.offset().top;
                $('body, html').stop().animate({scrollTop: scrollTo + 'px'}, 800);
            }
        }

    });
}
smoothScrollAnchorLinks();

function scrollToBlock(cur_block, callback, additionalOffset) {
    if ($(cur_block).length == 0) {
        return;
    }

    var isIn = checkIsIN();

    var additionalOffsetValue = additionalOffset;

    if (callback) callback(isIn);

    function checkIsIN() {
            var hT = cur_block.offset().top,
                hH = cur_block.outerHeight(),
                wH = $(window).height(),
                wS;

            if (additionalOffsetValue) {
                wS = $(window).scrollTop() + additionalOffsetValue;
            } else {
                wS = $(window).scrollTop();
            }


                return wS > (hT + hH - wH) && (hT > wS) && (wS + wH > hT + hH);
    }

    document.addEventListener('scroll', function () {
        if (checkIsIN()) {
            console.log('in view');
            cur_block.addClass('in');
            if (!isIn) {
                if (callback) callback(true);
                isIn = true;
            }

        } else {
            console.log('not in view');
            cur_block.removeClass('in');
            if (isIn) {
                if (callback) callback(false);
                isIn = false;
            }
        }
    }, {passive: true});
}
// scrollToBlock($('#letter-block'));
// scrollToBlock($('.worldwide--content-wrapper'), function (isIn) {
//     if (isIn) {
//
//     } else {
//
//     }
// });


// ===== Scroll to Top ====
document.addEventListener('scroll', function () {
    if ($(window).scrollTop() >= 200) {
        $('#scroll-to-top').addClass('in');
    } else {
        $('#scroll-to-top').removeClass('in');
    }
}, {passive: true});


$('#scroll-to-top').click(function (e) {
    e.preventDefault();
    $('body,html').animate({
        scrollTop: 0
    }, 500);
});

function _t(key, options) {
    let debugLang = false;
    let str = key;
    if (!(typeof LANG != 'undefined') && LANG[key]) {
        str = LANG[key];
    } else if (debugLang) {
        console.warn('Lang key missing', key);
    }

    if (options !== undefined) {
        for (let k in options) {
            if (options.hasOwnProperty(k)) {
                let v = options[k];
                str = (str + '').replace('%' + k + '%', v);
            }
        }
    }

    return str;
}


const toggleShortMenuDropdown = () => {
    let menuWithDropdown = $('.has-dropdown');
    let dropdownEl = menuWithDropdown.find('.menu__dropdown');

    menuWithDropdown.on('mouseenter', () => { 
        dropdownEl.addClass('opened');
    });

    dropdownEl.on('mouseleave', () => {
        dropdownEl.removeClass('opened');
    });

    $(document).on('click', () => {
        dropdownEl.hasClass('opened') ? dropdownEl.removeClass('opened') : '';
    });
};
toggleShortMenuDropdown();

$('.sbpay-subscribe-btn').click(function (event) {
    event.preventDefault();

    let form = $(this).closest('form');
    form.find('.alert-message').hide().removeClass('in');
    //$('#alert-success-message-new-sbpay-subscriber').hide();

    $.post(form.attr('action'), JSON.stringify({
        name: form.find('input[name=name]').val(),
        email: form.find('input[name=email]').val(),
    }), 'json')
        .done(result => {
            $('#popup--login-form').modal('hide');
            $('#form-modal-success').modal('show');
            form.find('.alert-message').removeClass('in');
            setTimeout(function (){
                $('#form-modal-success').modal('hide');
                $('.modal').click();
            }, 5000)
            //$('.modal').click();
        })
        .fail((xhr, status, error) => {
            if (xhr.responseJSON && xhr.responseJSON.errors && xhr.responseJSON.errors.length) {
                xhr.responseJSON.errors.map((parseError) => {
                    if (parseError) {
                        form.find('.error-' + parseError.path).html(parseError.error).show().addClass('in');
                    }
                })
            }
        });
});

$('#form-modal-success').on('shown.bs.modal', function () {
    let $checkbox = $('.ap--success-block-checkbox_ok');
    setTimeout(function (){
        $checkbox.addClass('in');
    }, 420);

});
$('#form-modal-success').on('hidden.bs.modal', function () {
    let $checkbox = $('.ap--success-block-checkbox_ok');
    $checkbox.removeClass('in');
});

let mercantPopup = $('#modal__merchant-login');
let mercantBtn = $('.merchant-login-btn');

if(mercantPopup || mercantBtn){
    mercantBtn.on('click', function (){
        if($(this).hasClass('merchant-login--connect')){
            $('.modal__merchant-login .merchant-login--connect').removeClass('hidden')
            $('.modal__merchant-login .merchant-login--disconnect').addClass('hidden');
        } else if($(this).hasClass('merchant-login--disconnect')){
            $('.modal__merchant-login .merchant-login--connect').addClass('hidden')
            $('.modal__merchant-login .merchant-login--disconnect').removeClass('hidden');
        }
    })
}